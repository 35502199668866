.alert-banner {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    color: #fff;
    z-index: 0;
    opacity: 0;
    transition: all 0.3s;
}

.alert-banner.show {
    opacity: 1;
}

.alert-banner.hide {
    opacity: 0;
    display: none;
}

.alert-banner.warning {
    background-color: #ffc107;
    /* Yellow */
}

.alert-banner.error {
    background-color: #dc3545;
    /* Red */
}

.alert-banner.info {
    background-color: #17a2b8;
    /* Blue */
}

.alert-banner.pink {
    background-color: #f9607d;
    /* Pink */
}

.alert-banner.success {
    background-color: #28a745;
    /* Green */
}

.alert-icon {
    margin-right: 10px;
    font-size: 24px;
    margin-top: -8px;
}

.alert-message {
    flex: 1;
    word-wrap: break-word;
    /* Add word-wrap property */
}

.alert-close {
    cursor: pointer;
    /* Set cursor to pointer for the close icon */
}

.small {
    padding: 4px 6px;
}
