@font-face {
    font-family: "Noto Sans KR";
    src: url("./NotoSansKR-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

html,
body,
body > #root > div {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 13px;
}

hr {
    margin: 5px 0 !important;
}

/* <Quill Editor> */
.hideqltoolbar .ql-toolbar.ql-snow {
    display: none;
    /* Hide Quill Editor header for none-editable mode */
}

.ql-toolbar.ql-snow {
    background-color: white;
}

.ql-container.ql-snow {
    border: 0 !important;
    /* Hide Quill Editor's grey border */
}

.ql-container {
    background-color: white;
    border-radius: 4px;
}

.ql-editor table {
    width: 100%;
}

.ql-editor td {
    border: 1px solid #e7d2d2;
    padding: 2px 5px;
}

.ql-editor td.bgColor {
    background-color: #d0b2b2;
}

/* </Quill Editor> */

.image-gallery {
    z-index: 0;
}

/* <myButton> */
.myButton {
    padding: 7px 13px;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}

.myButton a {
    color: white !important;
    text-decoration: none !important;
}

.myButton.submit {
    background-color: #0074d9;
}

.myButton.submit:hover {
    background-color: #03569e;
}

.myButton.submit.disabled:hover {
    background-color: #0074d9;
}

.myButton.secondary {
    background-color: #1a7046;
}

.myButton.secondary:hover {
    background-color: #124d30;
}

.myButton.secondary.disabled:hover {
    background-color: #1a7046;
}

.myButton.delete {
    background-color: #d63434;
}

.myButton.delete:hover {
    background-color: #ab1616;
}

.myButton.delete.disabled:hover {
    background-color: #d63434;
}

.myButton.cancel {
    background-color: #4a4949;
}

.myButton.cancel:hover {
    background-color: #312f2f;
}

.myButton.cancel.disabled:hover {
    background-color: #4a4949;
}

.myButton.extraRightSpace {
    margin-right: 10px;
}

.myButton.extraLeftSpace {
    margin-left: 10px;
}

.myButton.thinBtn {
    padding: 4px 7px;
}

.myButton.right {
    float: right;
    margin-bottom: 20px;
}

.myButton.disabled {
    cursor: not-allowed !important;
    opacity: 0.7;
}

.myButton.loading {
    cursor: not-allowed !important;
    opacity: 0.7;
}
/* </myButton> */

/* <ImageGallery> */
.image-gallery-slides {
    max-height: 400px;
}

.image-gallery-image {
    object-fit: fill;
    max-height: 400px !important;
}

/* </ImageGallery> */

/* <Mui Grid> */
.MuiGrid-root.MuiGrid-item a:hover {
    text-decoration: none !important;
}

/* </Mui Grid> */

/* <Mui Modal> */
.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    background-color: white;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

@media (max-width: 768px) {
    .modalContainer {
        width: 350px;
    }
}

.modalHeader {
    background-color: #d50056;
    padding: 10px;
    color: white;
    font-weight: 800;
}

.modalBody {
    padding: 15px;
}

.modalButtonBar {
    text-align: right;
}

/* </Mui Modal> */

.modal-body {
    padding: 0 !important;
}

/* <React Modal> */
.modal-header {
    padding: 10px 10px 7px 10px !important;
    border-bottom: 3px solid #d50056 !important;
    background-color: #f8eded !important;
}

.modal-header .modal-title.h4 {
    font-size: 1.15em;
    font-weight: 700;
    color: #292929;
}

.modal-body {
    font-family: "Noto Sans KR";
}

.modal-footer {
    border-top: 0 !important;
}
/* </React Modal> */

/* <React Tab> */
.nav-link {
    padding: 0.7rem 1rem;
    color: #a17070;
    font-size: 1.15em;
}

.nav-link:hover {
    color: #6d4040;
}
/* </React Tab> */

/* </React Banner> */
.alert-error {
    background-color: #eec5c9;
}
/* </React Banner> */

.tab-content > .active {
    background-color: white;
}

.invalid-feedback {
    width: auto !important;
}

.input-group {
    width: auto !important;
}

.input-group-text {
    padding: 0.3rem 0.75rem;
}

.MuiGrid-container {
    z-index: 1;
}

/* <Quill 에디터 내 Youtube영상 크기> */
.ql-video {
    width: 100%;
    height: 500px;
}
/* </Quill 에디터 내 Youtube영상 크기> */
