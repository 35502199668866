.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    width: 100%;
    max-width: 80rem;
    padding: 1rem 0rem;
    border-bottom: 0.5px solid lightgrey;
}

.circle_img {
    text-align: center;
    border-radius: 50%;
    height: 1.625rem;
    width: 1.625rem;
}

.small_item a {
    color: #146bc7 !important;
    text-decoration: underline !important;
}

.small_item a:hover {
    color: #4577ad !important;
    text-decoration: none !important;
}

.link,
.link:hover,
.link:visited,
.link:link,
.link:active {
    text-align: left;
    color: black;
    text-decoration: none;
}

.small_item {
    text-align: left;
    flex: 1;
}

.small_item_date {
    text-align: right;
    flex: 4;
}

.big_item {
    padding-left: 1rem;
    flex: 5;
    display: flex;
    flex-direction: column;
}

.category_text {
    color: grey;
    text-align: center;
    margin-left: 4px;
    font-size: x-small;
}

.tag_text {
    color: lightgrey;
    text-align: center;
    margin-left: 4px;
    font-size: xx-small;
}
