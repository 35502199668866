.profileListContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 80rem;
    width: 100%;
    background-color: white;
    padding: 0 10px;
}

.noData {
    margin: 10px 0;
}
