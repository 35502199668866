.contentContainer {
    width: 80em;
    margin: auto;
}

.htmlDiv {
    border: 1px solid #d5d5d5;
    padding: 10px;
    white-space: pre-line;
    font-size: 0.9em;
}

.contentTitle {
    font-size: 1.7em;
    font-weight: 700;
    margin-top: 10px;
    color: #d7094f;
    border-bottom: 3px solid #d7094f;
}

.explDiv {
    background-color: white;
}

.explDiv div {
    background-color: #fdf6f7;
    padding: 6px;
    margin: 0;
    border: 1px solid #ffc7cc;
}

.explDiv p {
    padding: 10px;
    border-left: 1px solid #ffc7cc;
    border-right: 1px solid #ffc7cc;
    border-bottom: 1px solid #ffc7cc;
    margin: 0;
    font-weight: 400;
}

.explDiv span {
    display: block;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .contentTitle {
        padding: 0 10px;
    }

    .contentContainer {
        width: auto;
    }
}
