.appContainer {
    /* Set background image to fixed (don't scroll along with the page) */
    background-attachment: fixed;
    /* Center the background image */
    background-position: top;
    /* Set the background image to no repeat */
    background-repeat: no-repeat;
    /* Scale the background image to be as large as possible */
    /*background-color: #f8f5f7;  파랑테마: #f3faff; */
    min-height: 90vh;

    /* For youtube */
    z-index: 1;
    position: relative;
    background-color: white;
    padding-bottom: 10px;
}
.youtubeBackground {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.youtubeVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1450px;
    pointer-events: none; /* Disable mouse events */
}

.youtubeText,
.youtubeSubText {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    color: rgb(235, 235, 235);
    text-align: center;
    text-shadow: 2px 2px 4px rgb(71 71 71 / 100%);
}

.youtubeText {
    font-size: 50px !important;
    margin-top: 250px;
    font-weight: 900;
}

.youtubeSubText {
    font-size: 17px !important;
    margin-top: 340px;
    padding: 0 50px;
}

@media (max-width: 768px) {
    .youtubeWrap {
        display: none;
    }
    .appContainer {
        margin-top: 0 !important;
    }
}

@media (min-width: 769px) and (max-width: 1080px) {
    .youtubeVideo {
        height: 600px;
    }
}

@media (min-width: 1081px) and (max-width: 1200px) {
    .youtubeVideo {
        height: 725px;
    }
}

@media (min-width: 1201px) and (max-width: 1600px) {
    .youtubeVideo {
        height: 850px;
    }
}

@media (min-width: 1601px) and (max-width: 1790px) {
    .youtubeVideo {
        height: 1000px;
    }
}

@media (min-width: 1791px) and (max-width: 1975px) {
    .youtubeVideo {
        height: 1100px;
    }
}

@media (min-width: 1976px) and (max-width: 2145px) {
    .youtubeVideo {
        height: 1200px;
    }
}

@media (min-width: 2146px) and (max-width: 2300px) {
    .youtubeVideo {
        height: 1300px;
    }
}
